<template>
 
  <v-app style="background-color:#f8f9fb" >

 


  <v-container   
    id="contrato"
    fluid
    tag="section" style="height:100%" class="parent-container">
    

    <div >
      <img src="../../assets/lg_grupo.png" width="80%" style="margin-top:2%;max-width: 300px">
    </div>



  </v-container>

</v-app> 
</template>

<script>

  export default {

  name: 'Inicio',
  
  components: {
  },
  
 
    data () {
      return {
       
      }
  },

    methods: {



    },
  }

 
</script>

<style scoped>


.parent-container {
  display: flex;
  justify-content: center; /* Centralizar horizontalmente */
  align-items: center; /* Centralizar verticalmente */
}

.parent-container img {
  max-width: 100%;
  max-height: 100%;
}


</style>